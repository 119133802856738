import constants from './actions/constants';

export const defaultState = {

    search: {
        isFetching: false,
        error: '',

        criteria: {
            // events: [],
            firstname: '',
            lastname: '',
            email: '',
            cellphone: '',
            currentEvents: true,
        },

        results: [],
    },

    checkin: {
        isFetching: false,
        error: '',
    },

    auth: {
        
        isFetching: false,
        error: '',

        testMode: false,

        authorized: false,
        url: 'https://seniorservices.lalgbtcenter.org/civicrm/ajax/api4',
        // siteKey: '73d034e730de9da89c8236e3730d4e41',
        // defaultApiKey: '6PGdFSRHhaAI0rchJOpiiaQ0', // this is the api key to very unprivliged user
        // apiKey: '',
        authorization: null, // this should be a jwt, but no support to generate dynamically currently
        contactId: null,
        
        live: {
            url: 'https://seniorservices.lalgbtcenter.org/civicrm/ajax/api4',
        },

        dev: {
            url: 'https://seniorservices.lalgbtcenter.org/civicrm/ajax/api4',
        },

    },
};

let initialState = {...defaultState};

const storedStateJSON = localStorage.getItem(constants.LOCAL_STORAGE_KEY);
if (storedStateJSON!==null) {
    try {
        const storedState = JSON.parse(storedStateJSON);
        
        // auth
        initialState.auth.testMode = storedState.auth.testMode;
        initialState.auth.authorized = storedState.auth.authorized;
        // initialState.auth.url = storedState.auth.url;
        initialState.auth.authorization = storedState.auth.authorization;
        initialState.auth.contactId = storedState.auth.contactId;
        
        // search
        // initialState.search = Object.assign({}, initialState.search, storedState.search);
        // initialState.search.isFetching = false;
        // initialState.search.error = '';

        // check in
        
    } catch (e) {
        // wasn't json i guess, no worries
    }
}

// console.log('defaultState', defaultState);
// console.log('initialState', initialState);
export default initialState; 

