const constants = {
    // misc
    LOCAL_STORAGE_KEY: 'SeniorsPromCheckInApp',

    // auth
    TEST_MODE:         "TEST_MODE",
    LOGIN:             "LOGIN",
    TRYING_LOGIN:      "TRYING_LOGIN",
    LOGIN_ERROR:       "LOGIN_ERROR",
    LOGGED_IN:         "LOGIN_ERROR",
    LOGOUT:            "LOGOUT",
    
    // search
    SEARCH_STARTED:    "SEARCH_STARTED",
    SEARCH_COMPLETED:  "SEARCH_COMPLETED",
    SEARCH_CRITERIA:   "SEARCH_CRITERIA",
    SEARCH_CLEAR:      "SEARCH_CLEAR",
    SEARCH_ERROR:      "SEARCH_ERROR",

    // checkin
    CHECKIN_STARTED:   "CHECKIN_STARTED",
    CHECKIN_COMPLETED: "CHECKIN_COMPLETED",
    CHECKIN_ERROR:     "CHECKIN_ERROR",
    
    // morphing data
    UPDATE_PARTICIPANT: "UPDATE_PARTICIPANT",
};

export default constants;