import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import constants from './actions/constants';

const store = createStore(
    reducer,
    applyMiddleware(thunk)
);

// Called when the state has changed.
store.subscribe(() => {
    const state = store.getState();
    // console.log('currenstate', state);
    const storeState = {
        auth: {
            testMode: state.auth.testMode,

            authorized: state.auth.authorized,
            // url: state.auth.url,
            authorization: state.auth.authorization,
            contactId: state.auth.contactId,
        },
        // search: {...state.search},
    };

    // storeState.search.isFetching = false;
    // storeState.search.error = '';

    // console.log('storeState', storeState);
    localStorage.setItem(constants.LOCAL_STORAGE_KEY, JSON.stringify(storeState));
});

export default store;