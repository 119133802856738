import React, { Component } from 'react';
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';
import { Typography, Button, Grid } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import logo from './assets/center_logo.png';
import './App.css';
import Login from './views/Login';
import Search from './views/Search';
import Results from './views/Results';
// import Result from './views/Result';
import NotFound from './views/NotFound';
import { logoutAction, toggleTestMode } from './store/actions/auth';

// MUI Update guid https://material-ui.com/guides/migration-v3/

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item sm>
              <img src={logo} className="App-logo" alt="logo" />
            </Grid>
            <Grid item sm>
              <Typography variant="h2">Seniors Check In</Typography>
            </Grid>
            <Grid item sm>
              {this.props.loggedIn ?
                <Button
                  className="logout"
                  variant="contained"
                  raised="true"
                  color="secondary"
                  onClick={() => this.props.logout()}
                  disabled={this.props.loading}
                >Logout</Button>
              : ''}
            </Grid>
          </Grid>
        </header>
        <main>
          {this.props.loggedIn ?
            <Switch>
              <Route path="/login" exact render={props => {
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
              }} />
              <Route path="/" exact render={props => {
                return <Redirect to={{ pathname: '/search', state: { from: props.location } }} />;
              }} />
              <Route path="/search" exact component={Search} />
              <Route path="/results" exact component={Results} />
              {/* <Route path="/results/:contact_id" exact component={Result} /> */}
              <Route component={NotFound} />
            </Switch>
          :
            <Switch>
              <Route path="/login" exact component={Login} />
              <Route  render={props => {
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
              }} />
            </Switch>
          }
        </main>
      </div>
    );
  }
}

// <App loggedIn={store.getState().auth.authorized} testMode={store.getState().auth.testMode} />
const mapStateToProps = state => {
  const { search, auth, checkin } = state;

  return {
    loading: search.isFetching || auth.isFetching || checkin.isFetching,
    loggedIn: state.auth.authorized,
    testMode: state.auth.testMode,
  };
}

// dispatch maps the respose of actions to the reducers to morph the state tree
const mapDispatchToProps = dispatch => {
  // console.log("App mapDispatchToProps", dispatch);
  return {
      logout: () => dispatch(logoutAction()),
      toggleTestMode: (bool) => dispatch(toggleTestMode(bool)),
  };
}
 
// Allows the class to be used in other files.
// Connect returns a new component that connected the props and store.
export default connect(mapStateToProps, mapDispatchToProps)(
  withTheme(App)
);
