import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import {
    IntegratedPaging, IntegratedSelection, IntegratedSorting,
    PagingState, SelectionState, SortingState
  } from '@devexpress/dx-react-grid';
  import {
    VirtualTable,
    Grid, PagingPanel,
    TableHeaderRow, 
    TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';
import { connect } from 'react-redux';
import { Fab, IconButton, LinearProgress, Paper, Typography } from '@material-ui/core';
import { Clear, CheckCircle } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { clearSearchResults } from '../../store/actions/search';
import { changeCheckInStatus } from '../../store/actions/checkin';

const styles = theme => ({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    paper: {
        margin: theme.spacing(2),
    },
});

class Results extends Component {

    startOver = () => {
        this.props.clearResults();
        this.props.history.push('/search');
    }

    render() {

        const { classes } = this.props;

        const addCheckIn = p => {

            const newStatus = p.status_id===2 ? 1 : 2;

            return (
                <IconButton 
                    color={p.status_id===2 ? "secondary" : "default"}
                    onClick={() => {
                        this.props.checkIn(p.id, newStatus);
                    }}
                    disabled={this.props.loading}
                >
                    <CheckCircle />
                </IconButton>
            );
        };

        const loadingStyleProps ={
            opacity: this.props.loading ? 1 : 0,
        };

        return (
            <div className="Results">
                <div className="loading" style={loadingStyleProps}>
                    <LinearProgress mode="indeterminate" color="secondary" />
                </div>
                <br />
                <Typography variant="h3" gutterBottom>Results</Typography>
                {this.props.error ?
                    <>
                        <br />
                        <Typography variant="h5" color="error" gutterBottom>{this.props.error}</Typography>
                    </>
                : ''}
                {this.props.results.length===0 ?
                    <Typography variant="h5" color="error" gutterBottom>-- No Results --</Typography>
                :
                    <>
                        <Paper className={classes.paper}>
                            <Grid
                                rows={this.props.results.map(p => {
                                    const { first_name, last_name } = p.contact;
                                    return {
                                        ...p,
                                        check_in: addCheckIn(p),
                                        first_name,
                                        last_name,
                                        email: p.contact['email_primary.email'],
                                        phone: p.contact['phone_primary.phone'],
                                    };
                                })}
                                columns={[
                                    // { name: 'contact_id', title: 'ID' },
                                    { name: 'check_in', title: 'Check In' },
                                    { name: 'first_name', title: 'First Name' },
                                    { name: 'last_name', title: 'Last Name' },
                                    { name: 'email', title: 'Email' },
                                    { name: 'phone', title: 'Phone' },
                                    { name: 'event_id.title', title: 'Event Type' },
                                    // { name: 'media_release', title: 'Media Release' },
                                    // { name: 'dietary_restriction', title: 'Dietary' },
                                    // { name: 'covid_vax', title: 'COVID Vax' },
                                    // { name: 'participant_status', title: 'Status' },
                                    // { name: 'custom_190', title: 'Emg. Contact Name' },
                                    // { name: 'custom_192', title: 'Emg. Contact Phone' },
                                ]}
                            >
                                <SortingState
                                    defaultSorting={[
                                        { columnName: 'first_name', direction: 'asc' },
                                    ]}
                                />
                                <PagingState
                                    defaultCurrentPage={0}
                                    defaultPageSize={15}
                                />
                                <SelectionState />

                                <IntegratedSorting />
                                <IntegratedPaging />
                                <IntegratedSelection />

                                <VirtualTable />
                                <TableColumnResizing 
                                    defaultColumnWidths={[
                                        // { columnName: 'contact_id', width: 75 },
                                        { columnName: 'check_in', width: 100 },
                                        { columnName: 'first_name', width: 150 },
                                        { columnName: 'last_name', width: 150 },
                                        { columnName: 'email', width: 200 },
                                        { columnName: 'phone', width: 150 },
                                        { columnName: 'event_id.title', width: 250 },
                                        // { columnName: 'media_release', width: 100 },
                                        // { columnName: 'dietary_restriction', width: 100 },
                                        // { columnName: 'covid_vax', width: 100 },
                                        // { columnName: 'participant_status', width: 100 },
                                        // { columnName: 'custom_190', width: 150 },
                                        // { columnName: 'custom_192', width: 150 },
                                    ]} 
                                />
                                <TableHeaderRow showSortingControls={true} />
                                
                                <PagingPanel 
                                    pageSizes={[5, 10, 15]} 
                                />
                            </Grid>
                        </Paper>
                    </>
                }
                <Fab 
                    color="primary"
                    className={classes.fab}
                    onClick={() => this.startOver()}
                    disabled={this.props.loading}
                >
                    <Clear />
                </Fab>
            </div> 
        );
    }
}

// Redux functions to connnect the component to the store.
const mapStateToProps = state => {
    const { search, checkin } = state;

    /*
     * convert to a participations list 
     */

    const results = [];
    if (search.results.length>0) {
        search.results.forEach(c => {
            const contact = {...c};
            delete contact.participations;
            c.participations.forEach(p =>{
                // console.log(p);
                // const dietary_restriction = ('custom_101' in p ? p.custom_101 : ('custom_66' in p ? p.custom_66: null));
                // switch (dietary_restriction) {
                //     case '1': // Meat Eater
                //         p.dietary_restriction = 'R';
                //         break;
                //     case '2': // Vegan
                //         p.dietary_restriction = 'V';
                //         break;
                //     case '3': // Gluten Free
                //         p.dietary_restriction = 'GF';
                //         break;
                //     default:
                //         p.dietary_restriction = '';
                //         break;
                // }
                // add values to results
                results.push({
                    contact,
                    ...p,
                });
            });
        });
        // console.log(results);
    }

    return {
        results: results,
        error: [search.error,checkin.error].filter(e => e.length>0).join(', '),
        loading: search.isFetching || checkin.isFetching, // not sure if the best case it to repull search results or just update object
    };
}
  
const mapDispatchToProps = dispatch => {
    // console.log("App mapDispatchToProps", dispatch);
    return {
        clearResults: () => dispatch(clearSearchResults()),
        checkIn: (id, newStatus) => dispatch(changeCheckInStatus(id, newStatus)),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
    withStyles(styles, { withTheme: true })(Results)
);